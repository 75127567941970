import React, { createContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { NextComponentType, NextPageContext } from "next";
import { useRouter } from "next/router";
import { useUpdateEffect } from "usehooks-ts";
import "@/styles/globals.css";
import { IntercomProvider } from "react-use-intercom";
import Layout from "@/containers/Layout";
import App, { AppContext } from "next/app";
import { ShopifyProvider } from "@/hooks/useShopify";
import { fetchAPI } from "@/lib/api";
import Head from "next/head";
import { HeaderData } from "@/components/Header";
import { FooterData } from "@/components/Footer";
import FacebookPixel from "@/components/FacebookPixel";
import LinkedInInsight from "@/components/LinkedInInsight";
import TagManager from "react-gtm-module";
import { AuthProvider } from "@/hooks/useAuth";

interface MyAppProps {
  Component: NextComponentType<
    NextPageContext,
    unknown,
    { pageProps: unknown }
  >;
  pageProps: {
    data: unknown;
    hideNavigation?: unknown;
    hideChatWidget?: boolean;
    showFooter?: boolean;
    hideHeaderNavigation?: boolean;
  };
  global: {
    id: number;
    attributes: {
      siteName: string;
      shopify: {
        enabled_presentment_currencies: string[];
      };
    };
  };
  header: HeaderData;
  footer: FooterData;
}

// Store Strapi Global object in context
export const GlobalContext = createContext({});

function MyApp(props: MyAppProps) {
  const { Component, pageProps, global, header, footer } = props;
  const {
    data,
    hideNavigation,
    showFooter,
    hideChatWidget,
    hideHeaderNavigation,
  } = pageProps;
  const { asPath } = useRouter();
  const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_WORKSPACE_ID || "";
  useEffect(() => {
    ReactGA.initialize("UA-183889084-2");
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY || "",
    });
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: asPath });
  }, [asPath]);

  useUpdateEffect(() => {
    const intercom = window.Intercom;
    if (intercom) {
      intercom("update", {
        hide_default_launcher: hideChatWidget === true,
      });
    }
  }, [hideChatWidget]);
  return (
    <>
      <Head>
        <title>{global?.attributes?.siteName}</title>
        <link rel="canonical" href={`https://tapt.io${useRouter().asPath}`} />
      </Head>
      <FacebookPixel />
      <LinkedInInsight />
      <GlobalContext.Provider value={global?.attributes}>
        <AuthProvider>
          <ShopifyProvider>
            <IntercomProvider
              appId={intercomAppId}
              autoBoot
              autoBootProps={{ verticalPadding: 80 }}
            >
              <Component pageProps={data} />
            </IntercomProvider>
          </ShopifyProvider>
        </AuthProvider>
      </GlobalContext.Provider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  // const appProps = await App.getInitialProps(appContext);
  const [appProps, globalRes, headerRes, footerRes] = await Promise.all([
    App.getInitialProps(appContext),
    fetchAPI("/global", { populate: "*" }),
    fetchAPI("/header", { populate: "*" }),
    fetchAPI("/footer", { populate: "*" }),
  ]);

  return {
    ...appProps,
    global: globalRes.data,
    header: headerRes.data,
    footer: footerRes.data,
  };
};

export default MyApp;
