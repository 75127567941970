import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import accountsApi from '@/lib/api/accounts';
import IUser from '@/types/IUser';
import { hasSessionToken } from '@/lib/services/axiosApiService';

interface AuthContextType {
  user?: IUser;
  orgID?: number;
  loading: boolean;
  getCurrentUser: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [user, setUser] = useState<IUser>();
  const [orgID, setOrgID] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  const getCurrentUser = useCallback(async () => {
    setLoading(true);
    try {
      const res = await accountsApi
        .getCurrentUser();
      if (res?.data?.data) {
        setUser(res.data.data);
        setOrgID(res.data.data.organisation_id);
      }
    } finally {
      return setLoading(false);
    }
  }, [setUser, setOrgID]);

  useEffect(() => {
    if (hasSessionToken()) {
      getCurrentUser().finally(() => setLoadingInitial(false));
    } else {
      setLoadingInitial(false);
    }
  }, [getCurrentUser]);

  const memoedValue = useMemo(
    () => ({
      user,
      orgID,
      loading,
      getCurrentUser,
    }),
    [user, orgID, loading, getCurrentUser],
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
